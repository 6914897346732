import _ from 'lodash';
import React, { useState, useMemo, createContext, useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getConnectedExchanges, getIMAP, getWEBHOOKS, saveConfig as uploadConfig } from 'redux/actions';
import { selectConfig } from 'redux/selectors';
import api from 'api';

import history from 'browserHistory';
import GunbotStorage, { INITIAL_GUNBOT_SETUP_MODE } from 'assets/js/gunbot.storage';

export const StartupGunbotSetupContext = createContext({
  activeStep: 0,
  ableToNext: true,
  setNextable: (activeStep, nexable) => { },
  setActiveStep: step => { },
  handleNext: () => { },
  handleBack: () => { },
  config: {},
  oldConfig: {},
  setConfig: config => { },
  startBot: () => { },
  saveConfig: config => { },
  symbol: 'exhange/pair',
  setSymbol: (exchange, pair) => { },
  openAddPairDialog: false,
  setOpenAddPairDialog: open => { },
  marketType: '',
  setMarketType: marketType => { },
  selectedExchange: '',
  setSelectedExchange: exchange => { },
  isBaseSetupMode: false,
  setBaseSetupMode: isBase => { },
  newLicensesData: {},
  setNewLicensesData: newLicensesData => { },
  authExchange: '',
  setAuthExchange: newAuthExchange => { },
  updateLicenses: async (wallet, licenses, verifyExchange, skipSaveConfig) => { },
  handleCleanConfig: () => { },
  showStepper: false,
  setShowStepper: () => { }
});

const StartupGunbotSetupProvider = ({ children }) => {
  const config = useSelector(selectConfig, _.isEqual);
  const dispatch = useDispatch();

  const [newConfig, setNewConfig] = useState();

  const [activeStep, setActiveStep] = useState(0);
  const [nextAbles, setNextAbles] = useState({});
  const [symbol, setSymbol] = useState('');
  const [openAddPairDialog, setOpenAddPairDialog] = useState(false);
  const [marketType, setMarketType] = useState('');
  const [selectedExchange, setSelectedExchange] = useState('');
  const [newLicensesData, setNewLicensesData] = useState(null)
  const [authExchange, setAuthExchange] = useState('')
  const [showStepper, setShowStepper] = useState(true)
  let [isBaseSetupMode, setBaseSetupMode] = useState(true);

  if (GunbotStorage.get(INITIAL_GUNBOT_SETUP_MODE, '') === '#startup') {
    isBaseSetupMode = true;
  } else if (_.isNil(config.GUI?.DASHBOARD_MODE)) {
    isBaseSetupMode = true;
  } else if (config.GUI?.DASHBOARD_MODE === 'base') {
    isBaseSetupMode = true;
  } else {
    isBaseSetupMode = false;
  }
  const setNextable = useCallback((activeStep, nextable) => {
    setNextAbles(prev => ({ ...prev, [activeStep]: nextable }));
  }, []);

  const handleNext = useCallback(() => {
    setActiveStep(prev => prev + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep(prev => prev - 1);
  }, []);

  const handleSymbol = useCallback((exchange, pair) => {
    if (!exchange || !pair) setSymbol('');
    else setSymbol(`${exchange}/${pair}`);
  }, []);

  const saveConfig = useCallback(config => {
    console.log('about to save config', config)
    dispatch(uploadConfig(config));
    dispatch(getIMAP());
    dispatch(getWEBHOOKS());
    dispatch(getConnectedExchanges());
  }, []);

  const startBot = useCallback(() => {
    const config = { ...newConfig, GUI: { ...newConfig.GUI, start: true } };
    dispatch(uploadConfig(config));
    dispatch(getIMAP());
    dispatch(getWEBHOOKS());
    dispatch(getConnectedExchanges());
    history.push('/');
  }, [newConfig]);

  const updateLicenses = async function (wallet, licenses, verifyExchange, skipSaveConfig) {
    const result = await api.editRegisteredKeys(wallet, licenses, verifyExchange);
    let configToWrite = JSON.parse(JSON.stringify(config))

    let returnValue = {
      status: 'none',
      message: ''
    }

    if (result?.status === 'success') {
      // remove pairs and exchanges, when exch no longer in licenses
      Object.keys(configToWrite?.pairs || {}).forEach(element => {
        if (_.isNil(licenses?.[element])) {
          delete configToWrite.pairs[element]
        }
      });

      Object.keys(configToWrite?.exchanges || {}).forEach(element => {
        if (_.isNil(licenses?.[element])) {
          delete configToWrite.exchanges[element]
        }
      });

      // update local license data
      api
        .checkGunthyWallet(wallet)
        .then(result => {
          if (result?.status === 'success') {
            GunbotStorage.set('licenseDetails', result.details);
            GunbotStorage.set('licenseDetailsLastUpdate', Math.round(+new Date() / 1000));
          }
        })

      // stop core after license change
      configToWrite.GUI.start = false

      let licenseDetails = GunbotStorage.get('licenseDetails', null);
      licenseDetails.activeKeys = result.licenses

      GunbotStorage.set('licenseDetails', licenseDetails);
      GunbotStorage.set('licenseDetailsLastUpdate', Math.round(+new Date() / 1000));

      returnValue.status = 'success'
      returnValue.message = 'License changes successfully saved.'

      setTimeout(() => {
        // delaying this a bit so that success message at least briefly shows
        if (!skipSaveConfig) {
          saveConfig(configToWrite)
        }
      }, 1500);

      return returnValue
    }
    else {
      returnValue.status = 'error'
      returnValue.message = 'License changes could not be saved. Ensure using a working exchange to verify the request with.'
    }

    return result
  };

  // removes orphaned pairs and exchanges
  const handleCleanConfig = function (licenses) {
    const registeredExchanges = Object.keys(licenses)
    let configToWrite = JSON.parse(JSON.stringify(config))

    if (!_.isNil(configToWrite.pairs)) {
      Object.keys(configToWrite.pairs).forEach(element => {
        if (!registeredExchanges.includes(element)) {
          delete configToWrite.pairs[element]
        }
      });
    }

    if (!_.isNil(configToWrite.exchanges)) {
      Object.keys(configToWrite.exchanges).forEach(element => {
        if (!registeredExchanges.includes(element)) {
          delete configToWrite.exchanges[element]
        }
      });
    }

    saveConfig(configToWrite)
  };

  const value = useMemo(() => {
    const ableToNext = nextAbles[activeStep];
    return {
      activeStep,
      ableToNext,
      setNextable,
      setActiveStep,
      handleNext,
      handleBack,
      config: newConfig,
      setConfig: setNewConfig,
      oldConfig: config,
      startBot,
      saveConfig,
      symbol,
      setSymbol: handleSymbol,
      openAddPairDialog,
      setOpenAddPairDialog,
      marketType,
      setMarketType,
      selectedExchange,
      setSelectedExchange,
      isBaseSetupMode,
      setBaseSetupMode,
      newLicensesData,
      setNewLicensesData,
      updateLicenses,
      authExchange,
      setAuthExchange,
      showStepper,
      setShowStepper,
      handleCleanConfig
    };
  }, [
    activeStep,
    nextAbles,
    newConfig,
    symbol,
    openAddPairDialog,
    marketType,
    selectedExchange,
    startBot,
    isBaseSetupMode,
    newLicensesData,
    authExchange,
    showStepper,
  ]);

  useEffect(() => {
    setNewConfig(config);
    if (config.GUI?.DASHBOARD_MODE === 'legacy') {
      localStorage.setItem('initial_gunbot_setup_mode', '"#legacy"')
      setBaseSetupMode(false)
    } else if (config.GUI?.DASHBOARD_MODE === 'base') {
      localStorage.setItem('initial_gunbot_setup_mode', '"#startup"')
    }
  }, [config]);

  return <StartupGunbotSetupContext.Provider value={value}>{children}</StartupGunbotSetupContext.Provider>;
};

export const useStartupGunbotSetup = () => useContext(StartupGunbotSetupContext);

export default StartupGunbotSetupProvider;
